<template>
  <a-modal width="60%" :label-col="4" :wrapper-col="14" :visible="open" @ok="onOk" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>选择广告申请记录</b>
    </a-divider>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('广告投放审核.联系电话')" prop="mobile">
                <a-input v-model="queryParam.mobile" :placeholder="$t('通用.输入.请输入')+$t('广告投放审核.联系电话')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('广告投放审核.姓名')" prop="realName">
                <a-input v-model="queryParam.realName" :placeholder="$t('通用.输入.请输入')+$t('广告投放审核.姓名')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('广告投放审核.广告名称')" prop="adsName">
                <a-input v-model="queryParam.advertName" :placeholder="$t('通用.输入.请输入')+$t('广告投放审核.广告名称')" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('广告投放审核.广告活动开始时间')" prop="createBeginTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"
                                 :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                                 valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('广告投放审核.广告活动结束时间')" prop="createEndTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"
                                 :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"
                                 valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? $t('通用.按钮.收起') : $t('通用.按钮.展开') }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <!-- 操作 -->
      <!-- 数据展示 -->
      <a-table
        :scroll="{ x: '130%' }"
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, type: 'radio', onChange: onSelectChange}"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
            {{ parseTime(record.createTime) }}
          </span>
        <template slot="picture" slot-scope="text, record">
          <div>
            <img v-if="record.picture" :src="record.picture"  style="width:60px;height:62px;" @click="handleRecordPreview(record.picture)" alt="none"/>
          </div>
        </template>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </a-modal>
</template>

<script>
import {pageAdvertisement} from '@/api/advert/apply'
import {mapGetters} from 'vuex'
import moment from 'moment';
import CustomDictTag from "@/components/DictCustomTag";
import {listCategory} from "@/api/goods/category";
import Editor from "@/components/Editor/Editor";
import { tableMixin } from '@/store/table-mixin'
import {allBrand} from "@/api/goods/brand";


export default {
  name: 'RadioGoodsSelectForm',
  props: {},
  components: {
    Editor,
    CustomDictTag,
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      pageSizeOptions: ['1', '20', '50', '100'],
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      open: false,
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        mobile: null,
        realName: null,
        advertName: null,
        describe: null,
        createBeginTime: null,
        createEndTime: null,
        status: 2,
        failReason: null,
        examineName: null,
        examineTime: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: this.$t('广告投放审核.姓名'),
          dataIndex: 'realName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('广告投放审核.联系电话'),
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('广告投放审核.广告名称'),
          dataIndex: 'advertName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('广告投放审核.广告简介'),
          dataIndex: 'describe',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('广告投放审核.广告活动开始时间'),
          dataIndex: 'startTime',
          scopedSlots: { customRender: 'startTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('广告投放审核.广告活动结束时间'),
          dataIndex: 'endTime',
          scopedSlots: { customRender: 'endTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('广告投放审核.审核状态'),
          dataIndex: 'statusName',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: this.$t('广告投放审核.审核状态描述 审核中,已通过待投放,已投放,已结束'),
          dataIndex: 'statusDescribe',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('广告投放审核.审核失败原因'),
          dataIndex: 'failReason',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('广告投放审核.审核人'),
          dataIndex: 'examineName',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: this.$t('广告投放审核.创建时间'),
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('广告投放审核.审核时间'),
          dataIndex: 'examineTime',
          scopedSlots: { customRender: 'examineTime' },
          ellipsis: true,
          align: 'center'
        },
      ]
    };
  },
  filters: {},
  created() {

  },
  computed: {
    ...mapGetters(['customDict']),
  },
  watch: {},
  mounted() {
  },
  methods: {
    moment,
    onClose() {
      this.open = false
      this.resetQuery()
    },
    /**
     *
     * @param records 会先过来的id
     */
    openApply(records) {
      if (records) {
        console.log('带过来的key',this.selectedRowKeys)
        this.selectedRowKeys = records;
      }
      this.getList()
      this.open = true;
    },
    onOk() {
      //判断有没有选择,拿到列表里选择的
      this.open = false
      this.$emit('applySelect', this.selectedRows)
      this.resetQuery()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.resetQuery()
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询明星列表 */
    getList () {
      this.loading = true
      pageAdvertisement(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.selectedRowKeys= [];
      this.selectedRows= [];
      this.queryParam= {
        userId: null,
          mobile: null,
          realName: null,
          advertName: null,
          describe: null,
          createBeginTime: null,
          createEndTime: null,
          status: 2,
          failReason: null,
          examineName: null,
          examineTime: null,
          pageNum: 1,
          pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
  }
}
</script>
