import request from '@/utils/request'


// 查询活动记录列表
export function listActivity(query) {
  return request({
    url: '/activity/activity/list',
    method: 'get',
    params: query
  })
}

// 查询活动记录分页
export function pageActivity(query) {
  return request({
    url: '/activity/activity/page',
    method: 'get',
    params: query
  })
}

// 查询活动记录详细
export function getActivity(data) {
  return request({
    url: '/activity/activity/detail',
    method: 'get',
    params: data
  })
}

// 查询活动记录详细
export function getActivityRank(data) {
  return request({
    url: '/activity/activity/rank',
    method: 'get',
    params: data
  })
}

// 新增活动记录
export function addActivity(data) {
  return request({
    url: '/activity/activity/add',
    method: 'post',
    data: data
  })
}

// 修改活动记录
export function updateActivity(data) {
  return request({
    url: '/activity/activity/edit',
    method: 'post',
    data: data
  })
}

// 删除活动记录
export function delActivity(data) {
  return request({
    url: '/activity/activity/delete',
    method: 'post',
    data: data
  })
}
export function activityMessage(data) {
  return request({
    url: '/activity/activity/message',
    method: 'post',
    data: data
  })
}

