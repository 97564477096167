import request from '@/utils/request'


// 查询广告投放审核列表
export function listAdvertisement(query) {
  return request({
    url: '/advert/apply/list',
    method: 'get',
    params: query
  })
}

// 查询广告投放审核分页
export function pageAdvertisement(query) {
  return request({
    url: '/advert/apply/page',
    method: 'get',
    params: query
  })
}

// 查询广告投放审核详细
export function getAdvertisement(data) {
  return request({
    url: '/advert/apply/detail',
    method: 'get',
    params: data
  })
}

// 新增广告投放审核
export function addAdvertisement(data) {
  return request({
    url: '/advert/apply/add',
    method: 'post',
    data: data
  })
}

// 修改广告投放审核
export function auditAdvertisement(data) {
  return request({
    url: '/advert/apply/audit',
    method: 'post',
    data: data
  })
}

// 删除广告投放审核
export function delAdvertisement(data) {
  return request({
    url: '/advert/apply/delete',
    method: 'post',
    data: data
  })
}
